.mobile-menu{display: none;}
@media (max-width: 1299px) {
    .header .header-login-container{max-width: 100%;}
}

@media (max-width: 1140px) {
    .header .header-login-container .links{display: none;}
    .mobile-menu{display: block;}
}

@media (max-width: 768px) {
    .header .header-title{padding: 1rem 0.75rem;}
    .header .header-title .picture-opacity-1 img{width: 30px;height: 30px;}
}

@media (max-width: 650px) {
    .header .category-link{display: none;}
}