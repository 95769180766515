@font-face {
    font-family: 'Manrope';
    src: url(assets/fonts/Manrope-SemiBold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Manrope';
    src: url(assets/fonts/Manrope-Bold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Manrope';
    src: url(assets/fonts/Manrope-ExtraBold.ttf);
    font-weight: 800;
}

@font-face {
    font-family: 'Manrope';
    src: url(assets/fonts/Manrope-ExtraLight.ttf);
    font-weight: 100;
}

@font-face {
    font-family: 'Manrope';
    src: url(assets/fonts/Manrope-Light.ttf);
    font-weight: 200;
}

@font-face {
    font-family: 'Manrope';
    src: url(assets/fonts/Manrope-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Manrope';
    src: url(assets/fonts/Manrope-Regular.ttf);
    font-weight: 400;
}