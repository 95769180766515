.module-Unit{
    background-color: #F3F6FF;
    /* border: 1px solid #234DCA; */
    padding: 10px 10px;
    border-radius: 5px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.module .form_group .right_text{
    padding: 3px 15px 4px 15px;
    background-color: white;
}
.module .form_group .right_text:focus{
    background-color: white;
}
.module .form_group .input-group .form-control{
    background-color: white;
}
.module .form_group .input-group .form-control:focus{
    background-color: white;
}
.module .unit-view-wrapper{
    border: 1px solid #E4E4E7;
    border-radius: 5px;
    overflow: hidden;

}
.module .unit-chapter-wrapper {
    background-color: #F3F6FF;
    margin-bottom: 10px;
    border-bottom: 1px solid #E4E4E7;
}
.module .unit-chapter-wrapper .unit-view-title{
    border-bottom: 1px solid #E4E4E7;
    padding: 5px 0;
    font-weight: 600;
}
.module .unit-chapter-wrapper .unit-chapter-view-title{
    padding: 5px 0;
    font-weight: 600;
}
.module .unit-chapter-wrapper .custom-color-blue{
    color: #234DCA;
}

.accordion-item{
   margin-bottom: 15px;
   border: 1px solid #234DCA;
   border-top: 1px solid #234DCA !important;
   border-radius: 5px;
   overflow: hidden;
}
.accordion-item .accordion-button{
    background-color: #F3F6FF;
}

.catalog-learning-outcome-wrapper,
.catalog-short-learning-outcome-wrapper{
    height: 70px;
    width: 100%;
   overflow: auto;
   scrollbar-width: none;
}
table tr td.catalog-td{
    width: 60% !important;
}

.form_group.normal-control.unit-input .form-control {
    background: #fff;
}