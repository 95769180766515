.login_main {
    position: relative;
    height: 100vh;
    background-image: url('./../assets/login_banner.webp');
    background-size: cover;
    background-position: center;
}

.scrollable {
    height: 100%;
    overflow: auto;
    padding: 10px;
}

.scrollable .inner-scroll{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_main::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(35, 77, 202, 0.8);
}

/* .login_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 77, 202, 0.8);
} */

.login_box {
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 470px;
    width: 100%;
    z-index: 1;
    padding: 0px 10px;
    position: relative;
}

.login_box .card {
    background-color: white;
    padding: 25px 20px;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.login_box .login-logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.login_box .login-logo picture {
    width: 35px;
    height: auto;
}

/* Optionally, if you have specific child elements that need to be centered vertically */
.login_box>* {
    margin-top: 10px;
}

.login_checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}


.login_box form {
    position: relative;
    z-index: 1;
    /* Ensure form is above the overlay */
}

/* Custom CSS */
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    border-color: red !important;
    /* Change to your desired border color */
}

.purchase-credit-container{
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
.purchase-credit-container .purchase-credit-box1{
    display: flex;
    flex-direction: column;
    background-color: #f3f5fa;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}
.purchase-credit-container .purchase-credit-box2{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.purchase-credit-box2 .title{
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
}
.purchase-credit-container .purchase-credit-box3{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px 0 ;
}
.purchase-credit-container .purchase-credit-box3 .title{
    font-weight: 700;
    font-size: 14px;
    color: #7C7C87;
    margin-top: 10px;
}
.purchase-credit-container .purchase-credit-box3 .description{
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}
.purchase-credit-container .purchase-credit-box3 .hr-line{
    width: 90%;
    color: #DEE4F1;
    border: 0; /* Remove default border */
    border-top: 2px solid #DEE4F1; /* Adjust the thickness as needed */
    margin: 0;
}
.purchase-credit-container .purchase-credit-box4{
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
}
.pricing-input-cursor-box{
    /* background-color: orange; */
    margin-top: 20px;
    margin-bottom: 10px;   
    position: relative; 
}
.price-credit-box .price-credit-input{
    width: 150px;
    height: 50px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
 }
.pricing-input-cursor-box .pricing-input-cursor{
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 35px;
    background-color: black;
    top: 8px;
    left: 136px;
    animation: blink 0.4s infinite alternate; 
    opacity: 0;
}
.pricing-input-cursor-box:hover .pricing-input-cursor {
    animation: none; /* Pause the animation on box hover */
    opacity: 0; /* Hide the cursor on box hover */
}
@keyframes blink {
    0% { opacity: 1; } /* Show the cursor */
    100% { opacity: 0; } /* Hide the cursor */
}


.thank-you-title{
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 20px;
 }
 .thank-you-title-1{
    font-weight: 600;
    font-size: 35px;
    margin-top: 20px;
 }
 .thank-you-title-2{
    font-weight: 400;
    font-size: 16px;
 }
 .thank-you-hr {
     color: #E0E0E0 !important;
     opacity: 1 !important;
     width: 99%;
     margin-top: 10px;
     border-top: 1px solid #E0E0E0
 }
 .thank-you-title-3{
    font-weight: 400 !important;
    font-size: 16px !important;
 }
 .thank-you-title-4{
    font-weight: 600 !important;
    font-size: 16px !important;
 }
 .thank-you-div-box{
    display: flex;
    gap: 100px;
    margin-bottom: 20px;
 }
 .custom-modal-width {
    width: 400px !important; /* Adjust the width as needed */
    /* margin: auto !important;  */
}
.profile-top-box{
    display: flex;
    justify-content: space-between;
}
.profile-top-box .profile-icon{
    width: 100px !important;
    height: 100px !important;
}
.profile-top-box .profile-info-box{
    display: flex;
    /* justify-content:center; */
    align-items: center;
    gap: 20px;
    padding: 5px 10px;

}
.profile-top-box .profile-info-description h6{
    margin: 0;
    padding: 0;
    margin-top: 6px;
}
.profile-top-box .profile-title1{
    background-color: #DAE3FF;
    color: #234DCA;
    padding: 1px 10px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 11px;
}
.profile-title2{
    font-weight: 600;
    font-size: 16px;
}
.profile-top-box .profile-phone{
    font-weight: 600;
    font-size: 14px;
    color: #7C7C87;
}
.profile-edit-button{   
    border: 1px solid #E1FADD !important;
    background-color: #E1FADD !important;
    color: #3AA828 !important;
}
.profile-edit-button-icon{
    background: #3AA828 !important;
}
.profile-delete-button{
    border: 1px solid #FFD9D9 !important;
    background-color: #FFD9D9!important;
    color: #FF2F2F !important;
}
.profile-delete-button-icon{
    background: #FF2F2F !important;
}
.react-datepicker-wrapper.form-xsm input {
    padding: 4px 10px 4px 25px;
    font-size: var(--fs-12);
}
.custom-table-date-filter{
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    padding: 5px;
    border: 1px solid #EEEEEE;
    height: 100%;
}
.custom-table-date-filter .react-datepicker__input-container input{
   min-width: 4rem;
   max-width: 120px;
   border: none;
   outline: none!important;
}
.custom-table-date-filter .react-datepicker__input-container{
    display: flex;
    align-items: center;
}
.custom-table-date-filter .date-right-border {
    border-left: 1px solid #E6E6E6;
    height: 100%;
    padding-right: 10px;
    margin-left: 10px;
}
.custom-table-date-filter .end-date{
    border: none;
    border-left: 1px solid #E6E6E6;
}
.react-datepicker__calendar-icon{
    padding: 4px !important;
}
.confirm-modal-btn{
    color: #007AFF !important;
    font-weight: 500 !important;
    font-size: 17px !important;
}
/* .checkbox {
    outline: 2px solid #9FA0A1;
}
.form-check-input:checked{
    background-color: #53D13Fed;
    border-color: #53D13Fed;
} */

.dashboard-first-box-first-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard-first-box-first-div h6{
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
}
.dashboard-sales-statics-box{
    padding: 10px;
}
.dashboard-sales-statics-box .col-2_0{
    width: 20%;
    height: fit-content;
}
.dashboard-first-box-card-one,
.dashboard-first-box-card-two,
.dashboard-first-box-card-three,
.dashboard-first-box-card-four,
.dashboard-first-box-card-five{
    border: none;
    border-radius: 10px;
}
.dashboard-first-box-card-one{
    background-color: #FFB526;
}
.dashboard-first-box-card-two{
    background-color: #B13DE7;
}
.dashboard-first-box-card-three{
    background-color: #EC1087;
}
.dashboard-first-box-card-four{
    background-color: #13B48E;
}
.dashboard-first-box-card-five{
    background-color: #824D4D;
}
.dashboard-sales-statics-box h6{
    font-weight: 800;
    font-size: 35px;
    color: #FFFFFF;
    margin-top: 10px;
}
.dashboard-sales-statics-box span{
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
}

/* =========== */

.dashboard-second-box-first-div h6{
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
}
.dashboard-course-statics-box{
    padding: 10px;
}
.dashboard-second-box-card-one,
.dashboard-second-box-card-two,
.dashboard-second-box-card-three,
.dashboard-second-box-card-four{
    border: none;
    border-radius: 10px;
}

.dashboard-second-box-card-one{
    background-color: #36D462;
}
.dashboard-second-box-card-two{
    background-color: #0EACF0;
}
.dashboard-second-box-card-three{
    background-color: #FE6D2F;
}
.dashboard-second-box-card-four{
    background-color: #413DF9;
}


.dashboard-course-statics-box h6{
    font-weight: 800;
    font-size: 35px;
    color: #FFFFFF;
    margin-top: 10px;
}
.dashboard-course-statics-box span{
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
}

.dashboard-course-statics-box .card{
    --bs-card-border-width:none;
}

/* ======= */